import React from 'react';

const H3thin = (props) => {
    return (
        <div style={{fontSize: 'var(--h3fontsize)',color:'var(--fontcolor)', opacity: props.opacity || .8, fontWeight: '400'}}{...props}>
            
        </div>
    );
}

const H3bold = (props) => {
    return (
        <div style={{fontSize: 'var(--h3fontsize)',color:'var(--fontcolor)', opacity: props.opacity || .8, fontWeight: '600'}}{...props}>
            
        </div>
    );
}

export const H3={
    thin:H3thin,
    bold:H3bold,
};
