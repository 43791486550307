import { useRouter } from 'next/router'
import React from 'react'
import NextNProgress from 'nextjs-progressbar';
import Head from 'next/head';
import Menu from '../components/compounds/menu';
import BottomNav from '../components/compounds/bottomnav'
import TopNav from '../components/compounds/topnav'
import '../styles/globals.css'
import { H3 } from '../components/primitives/text/h3';
import getprofile from '../services/getprofile';
import Player from '../components/compounds/player';
import LogInToContinue from '../components/compounds/logintocontinue';

export const UserContext = React.createContext();
export const PlayerContext = React.createContext();


function MyApp({ Component, pageProps:{session, ...pageProps} }) {

  const router = useRouter();
  const [layout, setLayout] = React.useState();
  const [showMenu, setShowMenu] = React.useState(false);
  const [showLoginToContinue, setShowLoginToContinue] = React.useState(false);
  const [menuContents, setMenuContents] = React.useState();
  const [userProfile, setUserProfile] = React.useState('unset')
  const [loading, setLoading] = React.useState(true);
  
  const [topBarProperties, setTopBarProperties] = React.useState({title:'', isbb:false, ispp:false,profileimage:'', backbuttonfunct:()=>{}});
  
  const [playContentState, setPlayContentState] = React.useState({changeplaystate:handlechangeplaystate, currentsong:null, changesong:handlechangesong, isplaying:false});
 
  const [currentSong, setCurrentSong] = React.useState();
  const [showPlayer, setShowPlayer] = React.useState(false);

  function handlechangeplaystate(){

  }

  async function handlechangesong(song){
    setCurrentSong(song);
    setPlayContentState((prop)=>{prop.currentsong = song; return prop});
    setShowPlayer(true);
  }

  const TopBarProps = {
    changeTitle:(title)=>{setTopBarProperties(prop =>{prop.title = title; return prop})},
    isBackButton:(isbb)=>{setTopBarProperties(prop =>{prop.isbb = isbb; return prop})},
    isProfileImage:(ispp)=>{setTopBarProperties(prop =>{prop.ispp = ispp; return prop})},
    changeProfileImage:(pp)=>{setTopBarProperties(prop =>{prop.profileimage = pp; return prop})},
    backbuttonfunct:(backbuttonfunct)=>{setTopBarProperties(prop =>{prop.backbuttonfunct = backbuttonfunct; return prop})},
  }
  async function hydrateUserProfile(){

    const response = await getprofile()
    if(response.status == "success"){
      setUserProfile(response.profile);    
    }else{
      setUserProfile(null);
    }
  }

  function handleOpenMenu(options){

      setMenuContents(options);
      setShowMenu(true);

  }

  function handleOpenShowLoginToContinue(){
    setShowLoginToContinue(true);
  }

  React.useEffect(() => {
    (()=>{
      setShowPlayer(false);
      setPlayContentState((prop)=>{prop.currentsong = null; return prop});
      setCurrentSong(null);})()

    if(router.pathname =='/'){
      setLayout(0);
    }
    else if(router.pathname.substring(0,20) =='/account/editprofile'){
      setLayout(2);

    }
    else if(router.pathname.substring(0,9) =='/account/'){
      setLayout(0);
    }
    else{
      setLayout(1);

    }

  },[router.asPath])

  React.useEffect(()=>{
      hydrateUserProfile().then(()=>{setLoading(false)});   

  },[])


  let UserContextVal = {
    islogged:((userProfile)=>{
        if(userProfile == 'unset') return 'unset';
        else if(userProfile == null) return false;
        else return true;
    })(userProfile),
    userpage: `/u/${userProfile?.username}`,
    hydrateUserProfile,
    userProfile,
    
  } 
  
  return <UserContext.Provider value={UserContextVal}>
            <Head>
                <title>Musicwall</title>
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"/>
                <meta property="og:site_name" content="Musicwall"/>
                <meta property="og:url" content="https://musicwall.cc"/>
                <meta property="og:type" content="website" />
                <meta name="twitter:image" content="https://www.musicwall.cchttps://musicwall.cc/IMG/og.png" />
                <meta property="og:image" content="https://musicwall.cc/IMG/og.png" />
                <meta name="twitter:card" content="https://musicwall.cc/IMG/og.png" />
                <link rel="icon" href="/SVG/logo_icon.svg" />
                <meta name="theme-color" content={'#1b1b21'}/>
                <meta name="keywords" content={`Musicwall,friends, share, music sharing, music, spotify, instagram for music, music discovery,frisson,find new music,music,soundcloud`}/>

                
                <meta property="og:title" content={"Musicwall "}/>
                <meta property="twitter:title" content={"Musicwall"}/>
                
                <meta name="title" content={"Musicwall"}/>

                <meta name="description" content={`Discover and share music that sparks joy.`}/>
                <meta name="twitter:description" content={`Discover and share music that sparks joy.`}/>
                <meta name="og:description" content={`Discover and share music that sparks joy.`}/>

              </Head>
            <PlayerContext.Provider value={playContentState}>
          
              <React.Fragment>
                  {
                    showLoginToContinue && <LogInToContinue
                                  close ={()=>setShowLoginToContinue(false)}
                                  />
                  }
                  {showMenu && <Menu 
                                  close = {()=>setShowMenu(false)} 
                                  options={menuContents}
                                />
                  }
                  {!loading && (layout !== 0) && (UserContextVal.islogged != 'unset') && <TopNav  
                                  layout={layout}
                                  topBarProperties={topBarProperties}
                                  handleOpenMenu ={handleOpenMenu}
                                />
                  }
                  <div 
                    style = {{paddingTop:0}}
                  >
                    <NextNProgress
                          options={{ showSpinner: false }}
                          color='var(--fontcolor)'
                          height={2} 
                          />
                      <Component 
                          TopBarProps={TopBarProps}
                          loading={loading} 
                          showModal = {showMenu || showLoginToContinue} 
                          handleOpenLoginToContinue={handleOpenShowLoginToContinue}
                          handleOpenMenu ={handleOpenMenu} 
                          {...pageProps} 
                      />
                  {showPlayer && <Player 
                      song ={currentSong} 
                      close = {()=>{
                                setShowPlayer(false);
                                setPlayContentState((prop)=>{prop.currentsong = null; return prop});
                                setCurrentSong(null);}}
                      />}

                  </div>

                  {!loading && (layout !== 0) && UserContextVal.islogged && !(layout == 2) && <BottomNav/>}
                
                </React.Fragment>
              </PlayerContext.Provider>
        </UserContext.Provider>
}

export default MyApp
