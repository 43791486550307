import React from 'react';
import styled from 'styled-components'

const DateInputStyle = styled.input`
    height:var(--inputsize);
    background: var(--inputcolor);
    border:1px solid var(--bordercolor);
    color: var(--fontcolor);
    outline: none;
    font-size:  var(--buttonfontsize);
    margin-inline:0 ;
    cursor: pointer;
    color-scheme:dark;
    width:100%;
    display: flex;
    padding-inline:20px;
    align-items: center;
    justify-content: center;
    border-radius:var(--inputradius);
    &.error{
        border:1px solid var(--errorred);
    }
    :hover{
        filter:brightness(95%);
    }
    `
const Label = styled.label`
    color: var(--fontcolor);
    opacity: .8;
    margin-left:20px;
    font-size: var(--labelfontsize);
`


export default function DateInput(props){

    return<div style={{width:'100%'}}>
        <Label>
            {props.label}
        </Label>
        <DateInputStyle {...props}>

        </DateInputStyle>
    </div>
} 