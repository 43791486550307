import Head from 'next/head';
import React from 'react';
import artistsToString from '../../../utils/artiststostring';
import { Icon } from '../../primitives/icons';
import Play from '../../primitives/icons/play';
import Image from '../../primitives/image';
import { H3 } from '../../primitives/text/h3';
import NoSongImage from '../searchresult/nosongimage';
import { Styles } from './styles';

export async function adjustVolume(element, newVolume){
    const duration = 1000; 
    const easing = ((p)=>{return 0.5 - Math.cos(p * Math.PI) / 2;});
    const interval = 13;

    const originalVolume = element.volume;
    const delta = newVolume - originalVolume;

    if (!delta || !duration || !easing || !interval) {
        element.volume = newVolume;
        return Promise.resolve();
    }

    const ticks = Math.floor(duration / interval);
    let tick = 1;

    return new Promise(resolve => {
        const timer = setInterval(() => {
            element.volume = originalVolume + (
                easing(tick / ticks) * delta
            );

            if (++tick === ticks + 1) {
                clearInterval(timer);
                resolve();
            }
        }, interval);
    });
}


const Player = (props) => {
    const [loading, setLoading] = React.useState();
    const [playState, setPlayState] = React.useState(false);
    const [trackProgress, setProgress] = React.useState()

    async function pause(){
        var sound = document.getElementById('musicplayer');
        sound.pause();

    }

    async function play(){
        var sound = document.getElementById('musicplayer');
        sound.play();


    }
    

    React.useEffect(()=>{
        var sound = document.getElementById('musicplayer');

         sound.ontimeupdate = function(){
            setProgress(`${(sound.currentTime/sound.duration) *100}%`);
        }

        sound.onplay = function(){
            setPlayState(true);
        }
        sound.onpause = function(){
            setPlayState(false);
        }

        setLoading(true);
        play();
    }, [props.song])

    return (
        <Styles.Wrapper>
            
           <Head>
            <title>
                {`${props.song.songName} • ${artistsToString(props.song.artists)}`}
            </title>
           </Head>

            <audio id ="musicplayer"
                   onCanPlayThrough={()=>{setLoading(false)}}
                   src ={props.song.previewURL}
                />
            <Styles.Cont>
            <Styles.ProgressBarCont>
                <div style={{
                            width: trackProgress
                }} className="progress"/>
            </Styles.ProgressBarCont>
            <Styles.ImageContainer>

                <Image
                    width = {45}
                    height = {45}
                    alt= {props.song.songName}
                    source = {props.song.albumArt}
                    placeholder ={()=><NoSongImage style={{padding:'10%'}} height ={'80%'}/>}
                    />

            </Styles.ImageContainer>

            <Styles.DetailsCont>
                <H3.bold>
                    {props.song.songName}
                </H3.bold>

                <H3.thin>
                    {artistsToString(props.song.artists)}
                </H3.thin>
            </Styles.DetailsCont>

            <Styles.ActionsCont>
                
                <button onClick={props.close}>
                    <Icon.Stop/>
                </button>

                <button onClick={() =>{(playState)?pause():play()}}>
                    {(loading)?
                    <Icon.Spinner/>
                    :
                    (playState)?<Icon.Pause/>:<Icon.Play/>}
                </button>

            </Styles.ActionsCont>
            </Styles.Cont>
          
        </Styles.Wrapper>
    );
}

export default Player;

