import customFetch from "../lib/customfetch";

export default async function logoutservice(){

    const URL = `/api/account/logout`;
   
    const PARAMS = {
        
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },

    };

    const RESP = await customFetch(URL,PARAMS);
    if(RESP.status == 'success'){
        return RESP.body;
    }

    return RESP;

}