import React from 'react';
import Image from '../../../primitives/image';
import NoProfile from './noprofile';

const Secondary = (props) => {
    return (
        <div style={{
            borderRadius:'var(--mainborderradius)',
            overflow: 'hidden',
            width: 'fit-content',
            height: 'fit-content',
        }}
            
            >
            <Image
                 blob = {props.blob}
                 source ={props.source} 
                 width = {'var(--profileimagesizesecondary)'}
                 height = {'var(--profileimagesizesecondary)'}
                 alt= {'Profile Image'}
                 placeholder ={()=><NoProfile height ={'70%'}/>}
                />
        </div>
    );
}

const Tertiary = (props) => {
    return (
        <div style={{
            borderRadius:'100%',
            overflow: 'hidden',
            width: 'fit-content',
            background:'var(--bordercolor)',
            height: 'fit-content',
            margin:-2,
            border:'2px solid var(--bordercolor)',
        }}
            
            >
            <Image
                 blob = {props.blob}
                 source ={props.source} 
                 width = {'var(--profileimagesizetertiary)'}
                 height = {'var(--profileimagesizetertiary)'}
                 alt= {'Profile Image'}
                 placeholder ={()=><NoProfile style={{marginTop:'30%',papaddingTop:'5%'}} height ={'90%'}/>}
                />
        </div>
    );
}


const Icon = (props) => {
    return (
        <div style={{
            borderRadius:'100%',
            overflow: 'hidden',
            width: 'fit-content',
            height: 'fit-content',
            border:'2px solid var(--grey)',
        }}
            
            >
            <Image
                 source ={props.source} 
                 width = {'var(--bottomnavactionsize)'}
                 height = {'var(--bottomnavactionsize)'}
                 alt= {'Profile Image'}
                 placeholder ={()=><NoProfile style={{marginTop:'30%',papaddingTop:'5%'}} height ={'90%'}/>}
                />
        </div>
    );
}


export const ProfileImage ={secondary: Secondary,
                            icon:Icon,
                            tertiary:Tertiary};
