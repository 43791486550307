import styled from 'styled-components';

const Wrapper = styled.div`
    padding:0px;
    position: fixed;
    z-index:10;
    bottom:55px;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    /* background:linear-gradient(#00000000 ,#00000022,#00000055,#000000aa); */

    `

const Cont = styled.div`
    background:var(--containerbg);
    border-radius: var(--mainborderradius);
    overflow:hidden;
    display: flex;
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 10px;
    padding:5px;
    /* height: fit-content; */
    height:58px;
    border: 1px solid var(--bordercolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    &.dummy{
        background:var(--containerbg);
        animation: placeholderShimmer 1.5s linear 0s infinite normal forwards;
    }
    `

const ProgressBarCont = styled.div`
    position:absolute;
    bottom:0;
    height:2px;
    /* background-color:var(--medlightgrey); */
    width: 100%;
    display: flex;
    left:0;

    & .progress{
        background-color: var(--white);
        height:2px;
        transition: width .1s;
    }

    `


const DetailsCont = styled.div`
    margin-left:10px;
    display: flex;
    /* padding-top:8px; */
    /* align-items: center; */
    height:100%;
    flex-direction: column;
    width:60%;
    justify-content: center;
    & div{
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
    }
    height:100%;
    `
const ActionsCont = styled.div`
    width:100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & button{
        height:30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        border:none;
        background:#00000000;
        cursor:pointer;
        margin-right: 10px;
        &.chosen{
            background:var(--mainred);
        }
        & svg{
            fill:var(--fontcolor);
            height:var(--actioniconsize);
            width: var(--actioniconsize);
        }
    }
    `

const ImageContainer = styled.div`  
    border-radius: 4px;
    overflow:hidden;
    display: flex;
    min-width:45px;
    
    height:fit-content;

    `
export const Styles = {ProgressBarCont, Cont, ImageContainer,Wrapper,DetailsCont, ActionsCont}
