import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { Form } from '../../../components/layouts/form';
import { Button } from '../../../components/primitives/buttons';
import { Icon } from '../../../components/primitives/icons';
import { Input } from '../../../components/primitives/inputs';
import { Logo } from '../../../components/primitives/logo';
import { H2 } from '../../../components/primitives/text/h2';
import { H3 } from '../../../components/primitives/text/h3';
import P from '../../../components/primitives/text/p';
import loginservice from '../../../services/login';
import { ValidityTest } from '../../../utils/validitytest';
import { UserContext } from '../../_app';

const Login = (props) => {
    const router = useRouter();
    const [password, setPassword] = React.useState();
    const [username, setUsername] = React.useState();
    const [error, setError] = React.useState();
    const [isWorking, setIsWorking] = React.useState(false);
    const [validateInputs, setValidateInputs] = React.useState({username:true,password:true});
    const {islogged, userpage} = React.useContext(UserContext);  

    React.useEffect(()=>{
        if(islogged === true){
            router.push(userpage);
        }
    },[islogged])

    async function handleSubmit(){
        setError(false)
        if(isWorking)return;

        setValidateInputs({username:true,password:true});
        const isInputsValid={username:ValidityTest.name(username),password:ValidityTest.password(password)}
        setValidateInputs(isInputsValid);
        
        if(!(isInputsValid.username && isInputsValid.password))return;

        setIsWorking(true);
        const serverresp = await loginservice(username, password)
        
        if(serverresp.status == "error"){
            if(serverresp.message == "invalid_details"){
                setError(`That details you entered are incorrect.`);
                setIsWorking(false);
                return;
            }
            setError(`An unexpected error occurred.  Please try again.`);
        }

        if(serverresp.status == "success"){
            location.href = props?.next || router.query?.next || "/feed"
        }

        setIsWorking(false);
        return; 
    }

    async function handleContinueWithSpotify(){
       location.href = "/api/auth/spotify/signin";
    }

    return (
        <React.Fragment>
            <Head>
                <meta name="theme-color" content={'#1b1b21'}/>
                <meta name="msapplication-navbutton-color" content="#1b1b21"/>
                <meta name="apple-mobile-web-app-status-bar-style" content="#1b1b21"/>
                <meta name="keywords" content={`musicwall, sign up, join`}/>
                <title>Musicwall | Sign up</title>
                <meta name="title" content={"Musicwall | Sign up"}/>

                <meta name="description" content={`Sign up for musicwall to start discovering and sharing music that sparks joy.`}/>
                <meta name="twitter:description" content={`Sign up for musicwall to start discovering and sharing music that sparks joy.`}/>
                <meta name="og:description" content={`Sign up for musicwall to start discovering and sharing music that sparks joy.`}/>

                <meta name="robots" content="index, nofollow"/>
                <meta name="language" content="English"/>
                <meta property="og:title" content={"Musicwall | Sign up"}/>
                <meta property="twitter:title" content={"Musicwall | Sign up"}/>

                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"/>
                <meta property="og:site_name" content="Musicwall"/>
                <meta property="og:url" content="https://musicwall.cc"/>
                <meta property="og:type" content="website" />
                <meta name="twitter:image" content="https://musicwall.cc/IMG/og.png" />
                <meta property="og:image" content="https://musicwall.cc/IMG/og.png" />
                <meta name="twitter:card" content="https://musicwall.cc/IMG/og.png" />
                <link rel="icon" href="/SVG/logo_icon.svg" />
            </Head>

            {(islogged != 'unset' && islogged != true) && <Form.Wrapper ismodal={props?.ismodal}>
                <Form.Container>
                <Form.InputCont>
                        </Form.InputCont>
                    {!props.ismodal && <Form.LogoCont >
                            <Logo.Icon.monochrome size={50}/>
                    </Form.LogoCont>}
                    <Form.InputCont>
                        <H2.normal >
                            Welcome back!👋
                        </H2.normal>

                        <div style = {{textAlign: 'center',padding:5, marginTop:5}}>
                            <P>
                                Fill out the details below to log in to your account.
                            </P>
                        </div>

                    </Form.InputCont>

                            <div style ={{marginBottom:10}}>
                                <Input.Default error={!validateInputs.username}  onInput={(e)=>{setUsername(e.target.value)}}   autoComplete="off" type = 'text' placeholder = "Username"/> 
                            </div>
                            <Input.Text error={!validateInputs.password} isWorking={isWorking} onInputChange={(e)=>{setPassword(e.target.value)}} buttonOnClick={handleSubmit} title ="Continue" buttonicon={<Icon.ArrowRight/>} autoComplete="off" type = "password" placeholder = "Password"/>
                            
                            <Form.ErrorCont>
                                <H3.thin>
                                    {error}
                                </H3.thin>
                            </Form.ErrorCont>
                    {/* <Form.Separator/> */}
                {/* <Button.primary icon ={<Icon.Spotify/>} bg = {'var(--spotifygreen)'} onClick = {handleContinueWithSpotify} title ="Continue with Spotify"/> */}
               
                <div style={{display:'flex', alignItems:'center', justifyContent:'center', textAlign:'center'}}>
                    <H3.thin>
                    <a>
                        <Link href ="/account/signup">
                        
                            {`Don't have an account?`}
                        </Link>
                        </a>
                    </H3.thin>
                </div>            

            </Form.Container>
        </Form.Wrapper>}
        </React.Fragment>
    );
}

export default Login;

