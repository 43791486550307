import React from 'react';
import styled from 'styled-components'

const SelectStyle = styled.select`
    height:var(--inputsize);
    background: var(--inputcolor);
    border:1px solid var(--bordercolor);
    color: var(--fontcolor);
    outline: none;
    font-size:  var(--buttonfontsize);
    margin-inline:0 ;
    cursor: pointer;
    color-scheme:dark;
    width:100%;
    display: flex;
    padding-left:20px;
    min-width: 80px;
    width: 100%;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    border-radius:var(--inputradius);
    margin-top:2px ;
    &.error{
        border:1px solid var(--errorred);
    }
    :hover{
        filter:brightness(95%);
    }
    `

const Label = styled.label`
    color: var(--fontcolor);
    opacity: .8;
    margin-left:10px;
    font-size: var(--labelfontsize);
    `

export default function Select(props){

    return<div>
        <Label>
        {props.label}
        </Label>
        <SelectStyle {...props}>

        </SelectStyle>
    </div>
} 