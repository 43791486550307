import React from 'react';
import { H3 } from '../../primitives/text/h3';
import { Styles } from './styles';

const Menu = (props) => {
    return (
       <Styles.Wrapper>
            <Styles.Cont>

            { props?.options.map((option) => {
                        return <Styles.MenuItem onClick = {()=>{option.function();props.close()}} title ={option.title} key={option.title}>
                                    <Styles.IconCont>
                                        {option.icon}
                                    </Styles.IconCont>
                                    <H3.thin>
                                      {option.title}
                                    </H3.thin>
                                </Styles.MenuItem>
                        }
                    )
            }
                
            </Styles.Cont>
            <Styles.Background onClick ={props.close}/>

       </Styles.Wrapper>
    );
}

export default Menu;
