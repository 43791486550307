const Icon ={
    colored:(props) => (
        <img src ={'/SVG/logo_icon.svg'}
            width={props.size || "60px"} height={props.size || "60px"} 
        />
    ),
    monochrome:(props) => (
        <img src ={'/SVG/logo_icon_monochrome.svg'}
            width={props.size || "60px"} height={props.size || "60px"} 
        />
    )}

const Expanded = {
    colored:(props) => (
        <img src ={'/SVG/logo_expanded.svg'}
             height={props.size || "60px"} 
        />
    ),
    monochrome:(props) => (
        <img src ={'/SVG/logo_expanded_monochrome.svg'}
            height={props.size || "60px"} 
        />
    )}
    

export const Logo={Expanded,Icon}