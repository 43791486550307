const name = (name) => {
    if(!name)return false;
    const regex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{3,20}$/;
    return regex.test(name);
}


const email = (email) => {
    if(!email)return false;
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
}

const password = (password) => {
    if(!password)return false;
    return (password?.length >7);
} 

export const ValidityTest ={name, email,password}