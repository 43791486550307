import styled from 'styled-components'

export const Wrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content: center;
    height:${(props) =>{return (props?.ismodal)?'100%':'100vh'}};
`

export const Container = styled.div`
    position:relative;
    overflow: hidden;
    padding-top:0px;
    padding:10px;
    padding-top:0px;
    padding-bottom:20px;
    width: 100%;
    background: var(--secondaryWhite);
    max-width: 390px;
    border-radius:4px;
    @media (max-width:500px){
        margin-top:-10%;
    }
`
export const NameInput = styled.div`
& div{
    margin-inline: 5px;
    width:100%;
    display:flex;
    align-items: center;
    & input{
        min-width: 170px;
        width: 170px;
    }
}
display: flex;
`

export const LogoCont = styled.div`
    margin-bottom:20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 370px;
    flex-direction:column;
 `

export const InputCont = styled.div`
margin-bottom:30px;
display: flex;
align-items: center;
justify-content: center;
width: 100%;

max-width: 370px;
flex-direction:column;

& a{
    max-width: 350px;
}

& h2{
    display: flex;
    align-items: center;
    margin: 0;
}

 `

export const ErrorCont = styled.div`
width:100%;
align-items:center;
margin-top:5px;
height: 25px;
display: flex;
padding-inline:10px;
max-width:350px;
& div{
    color:var(--errorred)!important;
}
`
export const Separator = styled.div`
    display:flex;
    align-items: center;

    padding:10px;
    padding-top:0;
    `

