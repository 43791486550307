import React from 'react';

const H2normal = (props) => {
    return (
        <h2 style={{fontSize: 'var(--h2fontsize)',margin:0,marginBottom:5, color:'var(--fontcolor)',fontWeight: '400'}}{...props}>
            
        </h2>
    );
}

const H2bold = (props) => {
    return (
        <h2 style={{fontSize: 'var(--h2fontsize)',margin:0,marginBottom:5, color:'var(--fontcolor)',fontWeight: '600'}}{...props}>
            
        </h2>
    );
}

export const H2={
    normal:H2normal,
    bold:H2bold,

};
