export default async function customFetch(url,params,timeout=10000){

    let result ={status:null,body:null,error:null};
    
        try {
            const response = await Promise.race([
                fetch(`${url}`,params).then((res)=>{ return res.json()}),
                new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), timeout))
                ]);
            result = {status:'success',body:response};

        }
        catch(e){

            if (e.message === 'Timeout'
                || e.message === 'Network request failed') {
                result = {status:'error', error:'A network error occurred.'}
            } else {
                result = {status:'error', error:'An unexpected error occurred.  Try again later.'} 
            }
        }
        
        finally {
            return result
        }
 

}