import React from 'react';
import Login from '../../../pages/account/login';
import { H3 } from '../../primitives/text/h3';
import { Styles } from './styles';
import { useRouter} from 'next/router';
import Head from 'next/head';

const LogInToContinue = (props) => {

    const router = useRouter();

    return (
       <Styles.Wrapper>

            <div style={{zIndex:3, marginBottom:10}}>
            <H3.bold opacity={1}>
                Log in to continue
            </H3.bold>
            </div>
            <Styles.Cont>
                <Login next={router.asPath}  ismodal/>
            </Styles.Cont>
            <Styles.Background onClick ={props.close}/>
       </Styles.Wrapper>
    );
}

export default LogInToContinue;
