import React from 'react';
import { H2 } from '../../primitives/text/h2';
import { Styles } from './style';
import {useRouter} from 'next/router';
import { Icon } from '../../primitives/icons';
import { ProfileImage } from '../profile/profileimage';
import { Button } from '../../primitives/buttons';
import { UserContext } from '../../../pages/_app';
import { Logo } from '../../primitives/logo';
import logoutservice from '../../../services/logout';
import Link from 'next/link';

const TopNav = (props) => {

    const router = useRouter();
    const [showBorder, setShowBorder] = React.useState(false);
    const [value, setValue] = React.useState();  
    const [chosen, setChosen] = React.useState();
    const {islogged,userpage, userProfile} = React.useContext(UserContext);  

    const menuOptionsMobile = [
        // {
        //     title: "Bookmarks",
        //     function:()=>{router.push('/bookmarks')},
        //     icon: <Icon.BookmarkSolid/>
        // },
        // {
        //     title: "Add app",
        //     function:()=>{},
        //     icon: <Icon.Download/>
        // },
        // {
        //     title: "Feedback",
        //     function:()=>{},
        //     icon: <Icon.Bug/>
        // },
        {
            title: "Logout",
            function:()=>{logoutservice().then(()=>location.href = '/') },
            icon: <Icon.Logout/>, 
            stayonclick: true,
        },
           
    ]
    const menuOptions = [
        {
            title: "View profile",
            stayonclick: true,
            function:()=>{router.push(userpage)},
            icon: <Icon.User/>
        },
        ...menuOptionsMobile
    ]

    React.useEffect(() => {
        setShowBorder(false)

        if(props.layout == 2){
            setShowBorder(true);
            return;
        }
    

        window.onscroll = () => {
            if (window.pageYOffset > 100){
                setShowBorder(true)
                }else{
                setShowBorder(false)
                }
            };
    
       
      }, [props.topBarProperties]);


    
    React.useEffect(() => {
        if(router.pathname.substring(0,3) == '/u/'){
            setChosen('u');
        }
        else if(router.pathname == '/newpost'){
            setChosen('p');
        }
        else if(router.pathname == '/feed'){
            setChosen('h');
        }
    }, [router.pathname,props.topBarProperties])

    function handleChangePage(path){
        router.push(path);
    }

    return (
        <Styles.Wrapper showborder ={showBorder}>
            <div className='innercont'>
                {!(props.layout == 2) && <Link href={'/'}>
                <div className='logoexpcont'>
                    <Logo.Expanded.colored size ={25}/>
                </div>
                <div hidden ={props.layout == 2} className='logoiconcont'>
                    <Logo.Icon.colored size ={25}/>
                </div>
                </Link>
                }
          <Styles.TextCont>

          {props.topBarProperties.isbb && <button onClick = {() =>props.topBarProperties.backbuttonfunct()} className = "backbutton">
                 <Icon.ArrowLeft/>
            </button>}
            
            {/* <div style = {{marginRight:'10px'}}>
                <Logo.Icon size ={30} />
            </div> */}
            
            <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>

                {props.topBarProperties?.ispp && <Styles.UserImageCont className = {((props.layout == 2) || showBorder)?'':'hidden'} >
                    <ProfileImage.icon source = {props.topBarProperties?.profileimage}/>
                </Styles.UserImageCont>}
                <H2.bold className='title'>
                {props.topBarProperties?.title || ' '}
            </H2.bold>
            </div>

        
          </Styles.TextCont>
         

        
       {(islogged == true)&& <Styles.NavContCont >
          <Styles.NavCont>
            
             <Styles.Button 
                    title ='Feed'
                    onClick = {() => handleChangePage('/feed')}
                    className={(chosen=='h')?'chosen':''}>
                    {!(chosen == 'h')?<Icon.Home className='icon'/>:<Icon.HomeSolid className='icon'/>} 

                
            </Styles.Button>

            {/* <Styles.Button 
                    title ='Notifications'
                    className={(chosen=='n')?'chosen':''}>
                <Icon.Bell/>
            </Styles.Button> */}

            <Styles.Button 
                    title ='New Post'
                    onClick = {() => handleChangePage('/newpost')}        
                    className={(chosen=='p')?'chosen':''}>
                    {!(chosen == 'p')?<Icon.PlusSquareRegular className='icon'/>:<Icon.PlusSquare className='icon'/>} 
            </Styles.Button>

            <Styles.Button 
                onClick = {() => props.handleOpenMenu(menuOptions)}        
                className={(chosen=='u')?'chosen':''}>
                    <Styles.IconCont className={(chosen=='u')?'chosen':''}>
                        <ProfileImage.icon source ={userProfile?.image}/>
                    </Styles.IconCont>
            </Styles.Button>

        </Styles.NavCont>
        </Styles.NavContCont>}

            {!(islogged === true) && <div style={{display:'flex'}}>
                <Link href={`/account/login?next=${router.asPath}`}>
                    <Button.secondary secondary title="Login"/>
                </Link>
                <Link href={"/account/signup"}>
                    <Button.secondary title="Sign up"/>
                </Link>
            </div>}

        {(router.asPath == userpage )&&<Styles.ActionsCont>
                
            
            <button onClick={() => props.handleOpenMenu(menuOptionsMobile)}>
                <Icon.Hamburger/>
            </button>
        </Styles.ActionsCont>}
        </div>
    </Styles.Wrapper>
    );
}

export default TopNav;
