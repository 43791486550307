import React from 'react';
import styled from 'styled-components'
import { Button } from '../../buttons';
import { Icon } from '../../icons';

export const Default = styled.input`
    height:var(--inputsize);
    background: var(--inputcolor);
    border:1px solid var(--bordercolor);
    color: var(--fontcolor);
    outline: none;
    font-size:  var(--buttonfontsize);
    margin-inline:0 ;
    color-scheme:dark;

    width:100%;
    display: flex;
    padding-inline:20px;
    align-items: center;
    justify-content: center;
    border-radius:var(--inputradius);
    &.error{
        border:1px solid var(--errorred);
    }
    :hover{
        filter:brightness(95%);
    }
    `
const Cont = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    `

const Text = (props) => {

    const [value, setValue] = React.useState();

    function handleEnterButton(e){
        if (e.key === "Enter") {
            if((value?.length>2) && props.validate){
                props.buttonOnClick()
            }
          }
    }
  
    return (
        <Cont>
            <Default onKeyPress={handleEnterButton} id='input' className={(props.error) ? "error":''} autoComplete="off" {...props} onInput ={(e)=>{setValue(e.target.value); props.onInputChange(e)}} placeholder={props.placeholder}/>
            <Button.circle onClick ={props.buttonOnClick} disabled = {!(value?.length>2) && props.validate} icon ={ (props.isWorking) ? <Icon.Spinner/>: props.buttonicon } title = {props.title}/>
        </Cont>
    );
}

export default Text;
