import styled from 'styled-components';

const Wrapper = styled.div`
    position:fixed;
    top:0;
    left:0;
    height:100vh;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    z-index: 220;
    `


const Background = styled.div`  
    background: var(--transparentbg);
    position:fixed;
    top:0;
    left:0;
    height:100%;
    width:100%;
    `
const IconCont = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    & svg{
        fill:var(--fontcolor);
        width: var(--actionicons);
        height: var(--actionicons);

        margin-right: 15px;
    }
    `
const Cont = styled.div`
    background:var(--meddarkgrey);

    border-radius: var(--mainborderradius);
    padding-bottom:0px ;
    scroll-snap-stop: always;
    min-width: 300px;
    border: 1px solid var(--bordercolor);
    z-index: 2;
    margin-bottom:-1;
    overflow: hidden;
    /* padding-bottom:10px; */

       `

const MenuItem = styled.div`
    height:50px;
    border-top: 1px solid var(--bordercolor);
    padding-inline:15px;
    display:flex;
    background:var(--meddarkgrey);
    cursor: pointer;
    align-items: center;
    &:nth-child(1) {
        border-top: none;

    }
    :active{
        filter:brightness(95%);
    }
    :hover{
        filter:brightness(105%);
    }
    `

export const Styles = {
    Wrapper,Background,Cont,MenuItem,IconCont
}