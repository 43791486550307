import React from 'react';
import styled from 'styled-components'

const Input = styled.textarea`
    height:calc(var(--inputsize) *3);
    background: var(--inputcolor);
    border:1px solid var(--bordercolor);
    color: var(--fontcolor);
    outline: none;
    font-size:  var(--buttonfontsize);
    margin-inline:0 ;
    color-scheme:dark;
    resize: none;
    cursor: pointer;
    width:100%;
    display: flex;
    padding:15px;
    padding-inline:20px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;


    align-items: center;
    justify-content: center;
    border-radius:var(--inputradius);
    &.error{
        border:1px solid var(--errorred);
    }
    :hover{
        filter:brightness(95%);
    }
    `

const Label = styled.label`
    color: var(--fontcolor);
    opacity: .8;
    margin-left:20px;
    font-size: var(--labelfontsize);
    `

const Multiline = (props) => {

    return (<div>
        <Label>
        {props.label}
        </Label>
        <Input {...props} placeholder={props.placeholder}/>

    </div>
    );
}

export default Multiline;
