import * as React from "react"

const ChevronDownIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg"
 
  viewBox="0 0 512 512" {...props}>
    <path d="m256 429.3 22.6-22.6 192-192 22.7-22.7-45.3-45.3-22.6 22.6L256 338.7 86.6 169.4 64 146.7 18.7 192l22.6 22.6 192 192 22.7 22.7z" />
  </svg>
)

export default ChevronDownIcon
