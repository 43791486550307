import styled from "styled-components";



const Wrapper = styled.div`
    height:53px;
    position:fixed;
    bottom:0px;
    z-index: 4;
    width:100%;
    background: var(--bottomnav);
    border-top: 1px solid var(--bordercolor);
    display: flex;
    justify-content: space-around;

    @media (min-width: 500px) {
        display: none;
    }
    `

const Button = styled.a`
    height:100%;
    display: flex;
    align-items: flex-start;
    padding: 4px;
    padding-top: 6px;
    opacity: .8;

    justify-content:center;
    &.chosen{
        opacity: 1;
        /* border-top: 1px solid var(--bottomnavactionchosencolor); */

    }
    width: 100%;
    & .icon{
        height: var(--bottomnavactionsize);
        width: var(--bottomnavactionsize);
        fill:var(--fontcolor);
    }
    `
const IconCont = styled.div`
    padding: 0px;
    border:2px solid #00000000;
    border-radius: 50%;

    &.chosen{
        border:2px solid var(--fontcolor);
    }
    `
export const Styles = {
    Wrapper,Button,IconCont
}