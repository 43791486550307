import React from 'react';
import styled from 'styled-components'
import { Icon } from '../icons';

const SecondaryButton = styled.button`
    height:var(--inputsize);
    background: ${(props)=>{return props.bg?props.bg:'var(--blurple)'}};
    border:none;
    color: var(--white);
    margin:20px ;
    font-size:  var(--buttonfontsize);
    margin-inline:0 ;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:2px;
    width: 100%;
    min-width: 80px;
    &.secondary{
        min-width: 50px;
        background: #00000000;
        margin-right:10px;
        width:fit-content;

    }

    :disabled{

        background: var(--lightgrey);
        color: var(--grey);

    }
    & svg{
        fill: var(--white);
        height: var(--iconsize);
        margin-right:10px ;
        width: var(--iconsize);

    }

    :hover{
        filter:brightness(105%);
    }
    `
const PrimaryButton = styled.button`
    height:var(--inputsize);
    background: ${(props)=>{return props.bg?props.bg:'var(--blurple)'}};
    color: var(--white);
    margin:20px ;
    font-size:  var(--buttonfontsize);
    margin-inline:0 ;
    cursor: pointer;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:var(--inputradius);
    
    & svg{
        fill: var(--white);
        height: var(--iconsize);
        margin-right:6px ;
    }
    & .loading{
        margin-right:10px ;

    }
    :hover{
        filter:brightness(105%);
    }
    :active{
        filter:brightness(95%);
    }
    :disabled{
        filter:grayscale(100%);
        cursor:not-allowed;
    }
    border:1px solid var(--blurple);
    border-color: ${(props)=>{return props.bg?props.bg:'var(--blurple)'}};

    &.secondary{
        background:#00000000;
    }
    `
const CircleButton = styled.button`
    border: 1px solid var(--bordercolor);
    :hover{
        filter:brightness(105%);
    }
    height:var(--inputsize);
    cursor: pointer;
    min-width:var(--inputsize);
    margin-left:10px;
    background-color: #00000000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:var(--inputradius);
    :disabled{
        & svg{
            fill: var(--lightgrey);
    }
    }
    & svg{
        fill: var(--blurple);
        height: var(--iconsize);

    }
    :active{
        filter:brightness(95%);
    }
    `

export const Button = {
    primary:(props) => {return <PrimaryButton className={(props.secondary)?'secondary':''} bg = {props.background} title={props.title} {...props}>
                           
                            {
                                props.loading?
                                <Icon.Spinner className='loading'/>
                                :props?.icon
                            }

                            {props.btitle || props.title}
                       </PrimaryButton>},
    circle:(props) => {return <CircleButton {...props} title={props.title}>
                            {props.icon}
                         </CircleButton>},
    
    secondary:(props) => {return <SecondaryButton className={props.secondary?'secondary':''} bg = {props.background} title={props.title} {...props}>
                            {props.title}
                         </SecondaryButton>},
    
    }
    


