import { useRouter } from 'next/router';
import React from 'react';
import { UserContext } from '../../../pages/_app';
import { Icon } from '../../primitives/icons';
import { H3 } from '../../primitives/text/h3';
import { ProfileImage } from '../profile/profileimage';
import { Styles } from './style';

const BottomNav = () => {
    const {userpage,userProfile} = React.useContext(UserContext)
    const router = useRouter();
    const [chosen, setChosen] = React.useState();

    
    React.useEffect(() => {
        if(router.asPath == userpage){
            setChosen('u');
        }
        else if(router.pathname == '/newpost'){
            setChosen('p');
        }
        else if(router.pathname == '/feed'){
            setChosen('h');
        }
    }, [router.asPath])

    function handleChangePage(path){
        router.push(path);
    }
    return (
        <Styles.Wrapper>

             <Styles.Button 
                    onClick = {() => handleChangePage('/feed')}
                    className={(chosen=='h')?'chosen':''}>
                        
                <Styles.IconCont>
                {(chosen == 'h')?<Icon.HomeSolid className='icon'/>:<Icon.Home className='icon'/>} 
                    </Styles.IconCont>
            </Styles.Button>

            {/* <Styles.Button className={(chosen=='n')?'chat':''}>
                <Icon.PaperPlane className='icon'/>
            </Styles.Button> */}

            <Styles.Button 
                    onClick = {() => handleChangePage('/newpost')}        
                    className={(chosen=='p')?'chosen':''}>
                        <Styles.IconCont>
                        {!(chosen == 'p')?<Icon.PlusSquareRegular className='icon'/>:<Icon.PlusSquare className='icon'/>} 
                    </Styles.IconCont>
            </Styles.Button>

            {/* <Styles.Button className={(chosen=='n')?'chosen':''}>
                <Icon.Bell className='icon'/>
            </Styles.Button> */}

            <Styles.Button 
                onClick = {() => handleChangePage(userpage)}        
                className={(chosen=='u')?'chosen':''}>
                    <Styles.IconCont className={(chosen=='u')?'chosen':''}>
                    <ProfileImage.icon source ={userProfile?.image}/>
                    </Styles.IconCont>
            </Styles.Button>

        </Styles.Wrapper>


    );
}

export default BottomNav;
