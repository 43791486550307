import ArrowLeft from "./arrowleft"
import ArrowRight from "./arrowright"
import Bell from "./bell"
import BookmarkRegular from "./bookmarkregular"
import BookmarkSolid from "./bookmarksolid"
import Comments from "./comments"
import Download from "./download"
import Elipsis from "./elipsis"
import ElipsisVertical from "./elipsisvertical"
import Hamburger from "./hamburger"
import HeartPlusSolid from "./heartplussolid"
import HeartRegular from "./heartregular"
import HeartSolid from "./heartsolid"
import Home from "./home"
import Logout from "./logout"
import Message from "./message"
import Minus from "./minus"
import PaperPlane from "./paperplane"
import Pencil from "./pencil"
import Plus from "./plus"
import Search from "./search"
import Share from "./share"
import LoadingSpinnerIcon from "./spinner"
import Spotify from "./spotify"
import UserCheck from "./usercheck"
import UserPlus from "./userplus"
import Bug from "./bug"
import User from "./user"
import Save from "./save"
import HomeSolid from "./homesolid"
import PlusSquare from "./plussquare"
import PlusSquareRegular from "./plussquareregular"
import UserMinus from "./userminus"
import Play from "./play"
import Pause from "./pause"
import X from './x'
import Stop from "./stop"
import Sparkles from "./sparkles"
import Equalizer from "./equalizer"
import ChevronDownIcon from "./chevrondownicon"
import ChevronUpIcon from "./chevronupicon"
import ChevronLeftIcon from "./chevronlefticon"
import ChevronRightIcon from "./chevronrighticon"



export const Icon = {ChevronDownIcon, ChevronUpIcon,ChevronLeftIcon,ChevronRightIcon,Equalizer, Sparkles, Stop, X, Pause, Play, UserMinus, PlusSquareRegular, PlusSquare, HomeSolid, Save, User,Bug, Download, Logout, HeartPlusSolid, Minus,ArrowLeft, Spinner:LoadingSpinnerIcon, Hamburger,Share, Message,Pencil,Spotify, ArrowRight, Search,Home, Plus, Bell, BookmarkSolid, BookmarkRegular,PaperPlane,HeartRegular,HeartSolid,Comments, ElipsisVertical,UserPlus,UserCheck, Elipsis}