import styled from "styled-components";


const NavContCont = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height:100%;

    `
const NavCont = styled.div`
    bottom:0px;
    height:100%;

    z-index: 4;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width:230px;
    @media (max-width: 500px) {
        display: none;
    }
    `
const ActionsCont = styled.div`
    width:fit-content;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    & button {
        display: flex;
        align-items: center;
        padding: 4px;
        /* width:35px; */
        height:35px;
        /* opacity: .; */
        cursor:pointer;
        :hover{
            filter:brightness(105%);
        }
        border: none;
        background:none;
        justify-content:center;
        border-radius: 30px;
        & svg{
            height: var(--bottomnavactionsize);
            fill:var(--fontcolor);
    }
    }

    @media (min-width:500px) {
       display:none;

    }
    `
const IconCont = styled.div`
padding: 0px;
border:2px solid #00000000;
border-radius: 50%;

&.chosen{
    border:2px solid var(--fontcolor);
}
`
const Button = styled.a`
height:100%;
display: flex;
align-items: flex-start;
padding: 4px;
margin-left:10px;
padding-top: 6px;
opacity: .8;

:hover{
            filter:brightness(105%);
        }

        :active{
            filter:brightness(95%);
      
        }
        
justify-content:center;
&.chosen{
    opacity: 1;
    /* border-top: 1px solid var(--bottomnavactionchosencolor); */

}
width: fit-content;
& .icon{
    height: var(--bottomnavactionsize);
    width: var(--bottomnavactionsize);
    fill:var(--fontcolor);
}
`
const TextCont = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    /* background-color:red; */
    text-align: center;
    height:100%;
    text-justify: center;

    & .title{
        text-align: center;
        display: flex;
        /* background-color: blue; */
        text-justify: center;
        margin: 0 !important;
    }

    & .backbutton{

        height:20px;
        width: 20px;
        background: var(--topbar);
        border-radius: 50%;

        :hover{
            filter:brightness(105%);
        }

        :active{
            filter:brightness(95%);
      
        }
        display: flex;
        padding: 1px;
        align-items:center;
        justify-content: center;
        margin-right:20px;
        cursor: pointer;
        border: none;

        & svg{
            fill:var(--fontcolor);
            height:var(--actioniconsize);
            width: var(--actioniconsize);
        }
    }
    `
const UserImageCont = styled.div`
    margin-right:10px;

   

    &.hidden{

    @media (max-width:500px) {
            display: none;
            transition: all .2s;
        }
    }
    `

const Wrapper = styled.div`
    height:61px;
    position:fixed;
    top:0px;
    
    z-index: 3;
    width:100%;
    background: var(--topbar);
    padding: 20px;
    padding-top: 21px;
    width: 100%;
    display:flex;
    justify-content: center;
    & .innercont{
        max-width: 1050px;
        width:100%;
        display:flex;
        align-items: center;

        & .logoexpcont{
            display: flex;
            align-items: center;
            justify-content: center;
            border-right:1px solid var(--bordercolor) ;
            margin-right: 10px;
            padding-right: 10px;
            @media (max-width: 1100px) {
                display:none;
           }
        }

        & .logoiconcont{
            display: flex;
            align-items: center;
            justify-content: center;
            border-right:1px solid var(--bordercolor) ;
            margin-right: 10px;
            padding-right: 10px;
            @media (min-width: 1100px) {
                display:none;
           }
        }
    }

    display:flex;
    align-items: center;
    border-bottom: ${(props)=>props.showborder ? '1px solid var(--bordercolor)' : '1px solid var(--topbar)'};
    @media (min-width:500px) {
        height:60px;
        padding-top:0px;
        margin-bottom:10px;
        display: flex;
        padding-bottom:0px;

    }
    @media (min-width: 1100px) {
        border-bottom: 1px solid var(--bordercolor);
    }

    border-bottom: ${(props)=>(props.showborder == 'force-no') ? '1px solid var(--topbar)!important' : ''};
    `

export const Styles = {
    IconCont,UserImageCont,
    Wrapper,NavCont,Button,NavContCont,TextCont,ActionsCont
}