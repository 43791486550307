import React from 'react'
import styled from 'styled-components'

const Spinner = styled.div`
    width: var(--iconsize);
    height: var(--iconsize);
    border: 2px solid  var(--white); 
    border-top: 2px solid #00000000; 

    border-radius: 50%;
    animation: spinner 1.5s linear infinite;

    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    `


export default Spinner;
