import React from 'react';
import { Icon } from '../icons';

async function fetchImage(url) {

    if(!url) return null;
    const data = await fetch(url).catch(() => { return null })

    if (data == null) {
        return null
    }

    if (data.status != 200) {
        console.log(`Error.  Resource ' ${url} ' returned ${data.status} - ${data.statusText}`)
        return null
    }

    return await data.blob().then((blob) => { return URL.createObjectURL(blob) })

}

const Image = (props) => {

    const [src, setSrc] = React.useState("unset");
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if(props.blob == 'loading') {
            setLoading(true);
            return;
        };

        if(props.blob !=null && props.blob !== 'loading'){
            // setSrc(null);
            console.log(props.blob);
            setSrc(props.blob)
            setLoading(false);

            return;
        } 

        if(!props.source) {
            setSrc(null);
            setLoading(false);

            return;
        }
        if(props.source == 'unset') {
            setLoading(true);
            return;
        }
        fetchImage(props.source).then((d)=>{
            setLoading(false);
            if(d){
                setSrc(d);
            }else{
                setSrc(null);
            }
        });

    },[props.source, props.blob])

    return (
        <div style={{width:props.width, 
                    transition:'all .2s',
                    height:props.height, 
                    background:(props.loading)?'var(--grey)':'',
                    display:'flex',
                    overflow:'hidden',
                    alignItems: 'center',
                    justifyContent: 'center',
                    }}>
           
           {(loading || src =="unset")?<Icon.Spinner style={{opacity:.6}}/>:
                (src || props.blob)
                ?
                <img 
                    style = {{objectFit: 'cover' , width: props.width, height: props.height}}
                    src = { src}
                    alt= {props.alt || ''}
                />
                :
                <div style={{
                    height:'100%',
                    width:'100%',
                    background:'var(--componentloadingbg)',
                    display:'flex',
                    overflow:'hidden',
                    alignItems: 'center',
                    justifyContent: 'center',
                    }}>
                    <props.placeholder/>
                </div>
                
                }
        </div>
    );
}

export default Image;
