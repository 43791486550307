import * as React from "react"

const Comments = (props) => (
  <svg
  aria-label="Comment"
  viewBox="0 0 24 24"
  {...props}
>
  <path
    d="M20.656 17.008a9.993 9.993 0 1 0-3.59 3.615L22 22Z"
    fill="none"
    stroke="var(--fontcolor)"
    strokeLinejoin="round"
    strokeWidth={2}
  />
</svg>
)

export default Comments
