import React from 'react';

const P = (props) => {
    return (
        <p style={{whiteSpace: 'pre-wrap', fontSize: 'var(--pfontsize)',margin:0,width:'100%',color:'var(--fontcolor)', opacity: props.opacity || .8, fontWeight: '400'}}{...props}>
            
        </p>
    );
}

export default P;
