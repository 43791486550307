import Multiline from "./multiline"
import Text, { Default } from "./text"
import DateInput from "./dateinput"
import Select from "./select"

export const Input = {
    Text,
    Multiline,
    Select,
    Date:(props)=><DateInput type="date" {...props}/>,
    Default: (props)=><Default {...props} className={(props.error) ? "error":''}/>
}
