import { H3 } from "../../primitives/text/h3";
import { Container, ErrorCont, InputCont, Wrapper,LogoCont } from "./styles";

const Separator=(props)=>{
    return <div style={{display:'flex', width:'100%',alignItems: 'center', justifyContent: 'center', marginTop:0, marginBottom:30}}>
        <div style={{width:'100%',marginInline:10,height:1, background:'var(--bordercolor)'}}/>
            <H3.thin opacity={.6}>
                OR
            </H3.thin>
            <div style={{width:'100%',marginInline:10,height:1, background:'var(--bordercolor)'}}/>
    </div>
}

export const Form = {Separator,LogoCont,Wrapper,Container,InputCont,ErrorCont}