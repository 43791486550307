import customFetch from "../lib/customfetch";

export default async function loginservice(username, password){

    const URL = "/api/auth/login";
   
    const PARAMS = {
        
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password })

    };

    const RESP = await customFetch(URL,PARAMS);
    
    if(RESP.status == 'success'){
        return RESP.body;
    }

    return RESP;

}